import React from 'react';

export const dashboardConfigs = [
  {
    routes: [
      {
        path: '/dashboard',
        component: React.lazy(() => import('./custom')),
      },
    ],
  },
];
