import {
  GET_EXISTING_CUSTOMER_REQUEST,
  UPDATE_EXISTING_CUSTOMER_REQUEST,
} from 'shared/constants/ActionTypes';

const initialState = {
  existingCustomerRequests: [],
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXISTING_CUSTOMER_REQUEST:
      return {
        ...state,
        existingCustomerRequests: action.payload,
      };
    case UPDATE_EXISTING_CUSTOMER_REQUEST:
      return {
        ...state,
        existingCustomerRequests: state.existingCustomerRequests.filter(
          (x) => x.customerNo !== action.payload,
        ),
      };
    default:
      return state;
  }
};

export default customerReducer;
