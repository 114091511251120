import React from 'react';

export const customerPagesConfig = [
  {
    routes: [
      {
        path: '/customer/existingRequest',
        component: React.lazy(() => import('./Pages/ExistingCustomerRequest')),
      },
    ],
  },
];
