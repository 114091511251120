import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {UPDATE_AUTH_USER} from '../../shared/constants/ActionTypes';
import {fetchStart, fetchSuccess, setJWTToken} from '../../redux/actions';
import api from '@crema/services/ApiConfig';
import {AuthType} from 'shared/constants/AppEnums';
import {defaultUser} from 'shared/constants/AppConst';

export const useAuthToken = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {user} = useSelector(({auth}) => auth);

  useEffect(() => {
    const jwtAthCheck = async () => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      if (!token) {
        dispatch(fetchSuccess());
        return;
      }
      dispatch(setJWTToken(token));
      try {
        const {data} = await api.get('auth/profile');
        if (data.role === 'ADMIN') {
          dispatch({
            type: UPDATE_AUTH_USER,
            payload: {
              authType: AuthType.JWT_AUTH,
              displayName: data.name,
              email: data.email,
              role: data.role,
              token,
              photoURL: data.avatar || defaultUser.photoURL,
            },
          });
        }
        dispatch(fetchSuccess());
        return;
      } catch (err) {
        dispatch(fetchSuccess());
        return;
      }
    };

    const validateAuth = () => {
      Promise.all([jwtAthCheck()]).then(() => {
        setLoading(false);
      });
    };
    validateAuth();
  }, [dispatch]);

  return [loading, user];
};

export const useAuthUser = () => {
  const {user} = useSelector(({auth}) => auth);

  if (user) {
    return {id: 1, ...user};
  }
  return [null];
};
