import {
  FETCH_START,
  FETCH_SUCCESS,
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../shared/constants/ActionTypes';
import {fetchError, fetchStart} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {defaultUser} from '../../shared/constants/AppConst';
import api from '@crema/services/ApiConfig';

export const onJwtSignIn = ({email, password}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {username: email, password};
    try {
      const {data} = await api.post('auth/login', body);
      if (data.user.role === 'ADMIN') {
        localStorage.setItem('token', data.accessToken);
        dispatch(setUser(data));
        dispatch(setJWTToken(data.accessToken));
      }
      dispatch(fetchError('Wrong username or password'));
    } catch (err) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError('Wrong username or password'));
    }
  };
};

export const setUser = (data) => {
  const {user, accessToken} = data;
  return async (dispatch) => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: {
        authType: AuthType.JWT_AUTH,
        displayName: user.name,
        email: user.email,
        role: user.role,
        token: accessToken,
        photoURL: user.avatar || defaultUser.photoURL,
      },
    });
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token,
    });
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch({type: FETCH_SUCCESS});
      localStorage.removeItem('token');
    }, 500);
  };
};
