const routesConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'sidebar.dashboard',
    type: 'item',
    icon: 'dashboard',
    url: '/dashboard',
  },
  {
    id: 'customer',
    title: 'Customer',
    messageId: 'sidebar.customer',
    type: 'collapse',
    icon: 'person',
    children: [
      {
        id: 'existingCustomerRequest',
        title: 'Existing Customer Request',
        type: 'item',
        messageId: 'sidebar.customer.existingCustomerRequest',
        url: '/customer/existingRequest',
      },
    ],
  },
];
export default routesConfig;
