import React from 'react';
import api from '@crema/services/ApiConfig';
import IntlMessages from '@crema/utility/IntlMessages';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_EXISTING_CUSTOMER_REQUEST,
  UPDATE_EXISTING_CUSTOMER_REQUEST,
} from 'shared/constants/ActionTypes';

export const getExistingCustomerRequests = (filter = 'UNHANDLED') => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    api
      .get(`/existing_customer_request?status=${filter}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_EXISTING_CUSTOMER_REQUEST, payload: res.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: FETCH_ERROR,
          payload: <IntlMessages id='message.somethingWentWrong' />,
        });
      });
  };
};

export const updateExistingCustomerRequest = (
  customerNo,
  status,
  warehouse,
) => {
  if (
    (status === 'ACCEPTED' &&
      warehouse !== 'CCC' &&
      warehouse !== 'SINTRUPVEJ') ||
    !customerNo
  ) {
    return;
  }
  return (dispatch) => {
    api
      .put('/existing_customer_request', {
        customerNo,
        warehouse,
        status,
      })
      .catch((e) => {
        dispatch({
          type: FETCH_ERROR,
          payload: <IntlMessages id='message.somethingWentWrong' />,
        });
      });

    dispatch({
      type: UPDATE_EXISTING_CUSTOMER_REQUEST,
      payload: customerNo,
    });
  };
};
